.App {
  text-align: center;
}

@import url('https://fonts.googleapis.com/css?family=Abril+Fatface');

:root {

  /* Legend */
  --cquHigh: rgb(196, 214, 91);
  --cquMedium: rgb(255, 177, 0);
  --cquLow: rgb(235, 30, 35);

  /*  */
  --cquOkay: rgb(196, 214, 91);
  --cquAlert: rgb(255, 177, 0);
  --cquError: rgb(235, 30, 35);

  /* Official */
  --cquGreen: rgb(196, 214, 91);
  --cquBlue: rgb(0, 48, 87);
  --cquCharcoal: rgb(88, 89, 91);
  --cquLightBlue: rgb(128, 150, 166);

  /* Unofficial */
  --cquLightCharcoal: rgb(210, 216, 224);
  --cquBlueLink: rgb(0, 66, 129);
  --cquMidBlue: rgb(84, 119, 150);

}

/* Scrollbars are not right on Firefox */


::-webkit-scrollbar {
  width: 0.8rem;
  height: 0.8rem;
}

::-webkit-scrollbar-track {
  background-color: var(--cquLightCharcoal);
}

::-webkit-scrollbar-thumb {
  background-color: var(--cquLightBlue);
  border-radius: .4rem;
}


/**
 * Primary page layout has:
 * ---------------------------------------------------------
 * - FIXED header, 100% width
 * - side srolling filter bars
 * - STICKY table header, stopping just under fixed header.
 * - side scrolling table.
 * - footer.
 */

body {
  background-color: var(--cquCharcoal);
  font-family: Arial;
  font-size: 16px;
  margin: 0;
  padding: 0;
  background-color: #eee;
}

/*
 * Use noScrolling when showing a popover or ModalBackground.
 */
.noScrolling {
  overflow: hidden;
}

div#root {
  display: flex;
  flex-direction: column;
  height: 100vh;
  cursor: default;
}

header {
  width: 100vw;
}

main {
  flex: auto;
  overflow-y: auto;
}

/**
 * Remaining CSS is loosely grouped by area.
 */

div.flexable {
  display: flex;
}

main.homePage {
  background-image: url(./homepage-background.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 4rem 4rem 4rem 4rem;
}

.UnitDashboard {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.UnitDashboard .sidebar {
  flex-shrink: 2;
}

.UnitDashboard .sidebar .ButtonWrapper {
  z-index: 10; /* put above filterbox */
  display: flex;
  flex-direction: column;
  width: 2rem;
  margin-right: 1rem;
  padding-top: 2rem;
}

.UnitDashboard .sidebar .ButtonWrapper button {
  margin-left: 0;
}

.UnitDashboard .sidebar .ButtonWrapper button > p {
  margin-left: -10px;
  padding: 24px 0px;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  text-orientation: mixed;
  white-space: nowrap;
}


.UnitWidgets {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  align-content: flex-start;
  overflow-y: auto;
}

.UnitDashboard .CourseWidgets {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.UnitDashboard .UnitSummary {
  align-self: flex-start;
  text-align: center;
  background-color: white;
  padding: 1rem;
  margin: 1rem;
  box-shadow: 5px 5px 10px #ddd;
}

.UnitDashboard .UnitOverview,
.UnitDashboard .UnitFilterCard {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 100%;
  background-color: white;
  margin: 0.5rem;
  box-shadow: 5px 5px 10px #ddd;
}

.UnitDashboard .UnitInfoBar {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.UnitDashboard h1 {
  color: var(--cquBlue);
  font-size: 1.7rem;
  margin: 0.2rem 1rem;
  padding: 0;
}

.UnitDashboard .dataTable td.horizontalBar {
  margin: 0;
  padding: 0;
  font-size: 0;
  border-bottom: 2px solid var(--cquGreen);
}

.UnitDashboard .dataTable thead th {
  background-color: inherit;
  color: inherit;
  padding-top: 0;
  padding-bottom: 0;
}

.UnitInfoBar .UnitInfo {
  text-align: center;
  width: 150px;
  background-color: #eee;
  margin: 0.5rem;
  padding: 0.5rem;
  flex-grow: 1;
}

.UnitInfoBar .UnitInfo h1 {
  color: var(--cquGreen);
  font-size: 4rem;
  margin: 0;
  padding: 0;
}

.UnitInfoBar .UnitInfo h2 {
  color: var(--cquBlue);
  font-size: 1.3rem;
  margin: 0;
  padding: 0;
}

.UnitWidget {
  text-align: center;
  min-height: 20rem;
  max-height: 20rem;
  overflow: hidden;
  background-color: white;
  width: 20rem;
  padding: 1rem;
  margin: 1rem;
  box-shadow: 5px 5px 10px #ddd;
}

.UnitWidgetWide {
  width: 30rem;
}

.UnitWidgetAutoWidth {
  width: auto;
}

.VetUnitWidgetWide {
  width: 65rem;
  min-height: 28rem;
  max-height: 28rem;
}

.UnitListBoxLos {
  width: 348px;
  float: right;
  margin: 16px;
}

.UnitListBoxInteractions {
  width: 348px;
}

.UnitWidget.disabled {
  display: none;
  /* opacity: 0.5; */
  /* box-shadow: none; */
}

.faded {
  opacity: 0.5;
  box-shadow: none;
}

.UnitWidget .empty {
  opacity: 0.5;
}

.UnitWidget h4 {
  text-align: left;
  margin: 0 0 0.4rem 0;
  padding: 0 0 0.4rem 0;
  border-bottom: 2px solid var(--cquGreen);
}

.UnitWidget h1 {
  font-family: 'Abril Fatface', serif;
  font-weight: 400;
  font-size: 6rem;
  margin: 0;
  padding: 1rem;
  color: var(--cquCharcoal);
}

.UnitWidget h2 {
  font-weight: 400;
  font-size: 2.5rem;
  color: var(--cquCharcoal);
}

.UnitWidget .widgetLegend {
  text-align: left;
}

main.homePage nav button {
  border-radius: 1rem;
  background-color: var(--cquGreen);
  text-shadow: 0 0 9px var(--cquCharcoal);
  width: 49%;
  margin: 5px;
}

main.homePage nav div.showMore {
  color: var(--cquLightCharcoal);
  text-align: right;
  cursor: pointer;
}

main.errorMessage {
  text-align: center;
}

div.errorMessageBlock {
  margin: 5vh auto;
  width: 60rem;
}

div.errorMessageBlock pre {
  background-color: #eee;
  padding: 1rem;
  overflow-x: scroll;
}

div.errorMessageDetails {
  border: 1px solid var(--cquGreen);
  border-radius: 1rem;
  background-color: white;
  padding: 1rem 2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

div.billiardBall {
  text-align: center;
}

div.billiardBall var {
  display: block;
  margin: 1.5rem auto;
  width: 260px;
  height: 260px;
  border-radius: 130px;
  font-style: normal;
  font-family: 'Abril Fatface', serif;
  font-weight: 400;
  font-size: 11.3rem;
  background-color: var(--cquLightBlue);
  color: white;
  text-shadow: 1px 0px 1px #a0b8a0, 0px 1px 1px #EEEEEE, 2px 1px 1px #a0b8a0, 1px 2px 1px #EEEEEE, 3px 2px 1px #a0b8a0, 2px 3px 1px #EEEEEE, 4px 3px 1px #a0b8a0, 3px 4px 1px #EEEEEE, 5px 4px 1px #a0b8a0, 4px 5px 1px #EEEEEE, 6px 5px 1px #a0b8a0, 5px 6px 1px #EEEEEE, 7px 6px 1px #a0b8a0;
}

div.billiardBall var small {
  margin: .5rem 0;
  font-style: normal;
  font-family: 'Abril Fatface', serif;
  font-weight: 400;
  font-size: 8rem;
  color: white;
}

nav {
  min-width: 60rem;
}

nav section {
  background: none;
}

nav.centerPanel {
  display: grid;
  grid-template-columns: repeat(3, minmax(280px, 1fr));
  gap: 2rem;

  max-width: 70rem;
  padding: 4rem;
  margin: auto;
  border: 1px solid rgba(255, 255, 255, 1);
  border-radius: 2rem;
  background-color: rgba(0, 0, 0, 0.3);
}

nav.centerPanel .showMore {
  grid-column: 1 / -1;
}

nav.buttonPanel {
  max-width: 70rem;
  padding: 2rem 4rem;
  margin: auto;
  font-size: 2rem;
  text-align: right;
}

div.tabPanel {
  padding-bottom: 5px;
  border-bottom: 2px solid #eee;
}

div.tabPanel span.tab {
  padding: 1rem 1rem .4rem 1rem;
  margin: 0 1rem 0 0;
  border-bottom: 0.4rem solid var(--cquLightCharcoal);
  cursor: pointer;
}

div.tabPanel span.tab.tabSelected {
  border-bottom: 0.4rem solid var(--cquBlue);
}

div.tabPanel a {
  color: var(--cquBlueLink);
}

div.tabPanel a:hover{
  text-decoration: underline;
}

section.centerGraph {
  text-align: center;
  background-color: white;
}

section.centerGraph div.legend {
  font-family: serif;
  font-style: italic;
  text-align: center;

}

.infoHeader h1 {
  font-size: 2rem;
  margin: 2rem 0 0.5rem 0;
}

.tabBody {
  padding: 1rem;
}

.tabBody .buttonRow {
  float: right;
}

.tabBody .buttonRowLeft {
  float: left;
}

.tabBody h3 {
  padding-top: 1rem;
}

nav h1 {
  margin: 0;
  font-family: serif;
  font-size: 4rem;
  color: var(--cquLightCharcoal);
}

nav h2 {
  margin: .5rem;
  font-family: serif;
  font-style: italic;
  font-size: 2.3rem;
  color: var(--cquBlue50);
}

header div.primaryHeader {
  color: white;
  background-color: var(--cquBlue);
}

header div.primaryHeader a {
  color: white;
}

header div.secondaryHeader {
  padding: .4rem 1.0rem .4rem 1.0rem;
  background-color: var(--cquCharcoal);
  font-size: 0.9rem;
  color: var(--cquLightCharcoal);
}

header div.secondaryHeader a {
  color: var(--cquLightCharcoal);
}

header div.secondaryHeader svg {
  margin: 0 .2rem;
}

header div.tertiaryHeader {
  padding: .4rem .7rem .4rem 1.2rem;
  font-size: 0.9rem;
  background-color: var(--cquLightCharcoal);
  color: var(--cquCharcoal);
}

header div.tertiaryHeader a {
  color: var(--cquCharcoal);
}

header div.tertiaryHeader svg {
  margin: 0 .4rem;
}

header div.primaryHeader div.titleBar {
  color: white;
  padding: .7rem .2rem .4rem 3rem;
  font-size: 1.3rem;
  min-height: 1.9rem;
  background-image: url(./cquni_logo_green.svg);
  background-repeat: no-repeat;
  background-position: 0.9rem 0.4rem;
  background-size: 1.35rem;
}

header div.primaryHeader div.menuBar {
  padding: 1rem 1rem;
  float: right;
  color: var(--cquLightCharcoal);
  display: flex;
}

header div.primaryHeader div.menuDivision {
  padding-right: 1rem;
  margin-right: 1rem;
  border-right: 1px dotted var(--cquLightBlue);
  color: white;
}

header div.primaryHeader div.menuDivision:last-child {
  padding-right: 0;
  margin-right: 0;
  border-right: none;
  color: white;
}

section.spinner {
  width: 100vh;
  text-align: center;
  font-size: 55rem;
  color: black;
}

header h3,
header h4{
  padding: .4rem .3rem;
  margin: 0;
}

article {
  width: 100%;
  margin-bottom: 1rem;

  display: flex;
  flex-direction: row;
}
article section#list {
  width: 80%;
}
article section#filter {
  width: 20%;
}

article.siteConfig {
  flex-wrap: wrap;
}

article.siteConfig section {
  margin-bottom: 1rem;
}

article.filterBar {
  height: 14.5rem;
  margin: 0;
}

article.filterBar section {
  border: 0;
  margin: 0;
}

article.filterBar section.filterBarConsole {
  height: 13.5rem;
  min-height: 13.5rem;
  max-height: 13.5rem;
  width: 10rem;
  min-width: 10rem;
  max-width: 10rem;
  margin: 0.5rem 0 0.5rem 0.5rem;
}

article.filterBar section.filterBox {
  height: 13.5rem;
  min-height: 13.5rem;
  max-height: 13.5rem;
  overflow-y: hidden;
  flex-shrink: 0;
  min-width: 9rem;
  margin: 0.5rem 0 0.5rem 0.5rem;
}

article.filterBar section.filterBox h4 a {
  color: white;
}

article.filterBar section.filterBox table {
  border-spacing: 0;
}

article.filterBar section.filterBox table tbody td {
  vertical-align: top;
  padding: 0;
}

article.filterBar section.filterBox div.verticalScroll {
  height: 11.5rem;
  overflow-x: hidden;
  overflow-y: auto;
  /**
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  **/
}

article.filterBar section.filterBox div.verticalScroll::-webkit-scrollbar {
  width: 0.5rem;
}

article.filterBar section.filterBox table.dataTable tbody tr td,
article.filterBar section.filterBox .Ctable.dataTable .col
{
  padding: 0.25rem;
}

article.filterBar section.filterBox table.dataTable tbody tr td:first-child,
article.filterBar section.filterBox .Ctable.dataTable .col:first-child
{
  padding-left: 0.5rem;
}

article.filterBar section.filterBox table.dataTable tbody tr td:last-child,
article.filterBar section.filterBox .Ctable.dataTable .col:last-child
{
  text-align: right;
  padding-right: 1.5rem; /* extra padding required for all browsers except chrome */
  -webkit-padding-end: 0.5rem; /* chrome only */
}

.Ctable {
  display: table;
  width: 100%;
}

/*.Ctable > *, */
.Ctable .row {
  display: table-row;
}

.Ctable .col {
  display: table-cell;
}

div.horizontalScroll {
  /* width: 100%; */
  overflow-x: auto !important;
}

table.dataTable tbody tr th,
.Ctable.dataTable .row.th .col
{
  padding: 0.2rem 0.5rem;
  background: var(--cquBlue);
  color: white;
  text-align: left;
}

table.dataTable tbody tr td,
.Ctable.dataTable .col
{
  padding: 0.2rem 0.5rem;
  background: white;
}

/*
table.dataTable tbody tr td {
  width: 100%;
}
*/

table.dataTable tbody tr td:last-child,
.Ctable.dataTable .col:last-child
{
  min-width: 2.4rem;
}

table.dataTable tbody tr:nth-child(even) td,
.Ctable.dataTable .row:nth-child(even) .col
{
  /* padding: 0.2rem 0.5rem; */
  background: #efefef;
}

.gradesWrapper .dataTable {
  width: 100%;
}
.gradesWrapper table.dataTable tbody tr td:last-child:not(:only-child),
.gradesWrapper .Ctable.dataTable .col:last-child:not(:only-child)
{
  text-align: right;
  min-width: 2.4rem;
}


table.interactionTable tbody tr td {
  white-space: normal;
  /** word-break: normal; // <-- was messing up long lines. **/
  overflow: auto;
  vertical-align: top;
}

table.interactionTable thead tr.secondarySorting td {
  background-color: var(--cquLightBlue);
  color: white;
  white-space: normal;
  word-break: normal;
  text-align: center;
  max-height: 1.5rem;
  padding: .3rem;
  line-height: 1.6rem;
  overflow: hidden;
}

.oddEvenBox {
  padding: 0.5rem;
  margin: 0.5rem;
  background: #efefef;
}

table.dataTable tbody tr:nth-child(even) td div.oddEvenBox {
  background: white;
}

table.cellAlignTop tbody tr td {
  vertical-align: top;
}

table.cellPadding tbody tr td {
  padding: 0.5rem;
}

section {
  margin-right: 1rem;
}

header,
section,
footer {
  background-color: #ffffff;
}

div.centeredColumn {
  background-color: white;
  width: 70rem;
  margin: auto;
}

div.centeredWideColumn {
  background-color: white;
  min-width: 90rem;
  margin: auto;
}

a {
  color: var(--cquBlue);
  text-decoration: none;
  draggable: none;
}

/**
 * ---------------------------------------------------------
 * Form elements
 * ---------------------------------------------------------
 */

.formError {
  padding-top: 10px;
  color: var(--cquError);
}

select {
  border: solid 1px rgb(210, 216, 224);
  border: solid 1px var(--cquLightCharcoal);
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  font-size: .875em;
  margin: 0;
  padding: 8px 16px;
  -webkit-transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: var(--cquCharcoal);
  background-color: white;
}

button {
  border: solid 1px var(--cquLightCharcoal);
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  font-size: .875em;
  margin: 0;
  padding: 8px 16px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1);
  user-select: none;
  color: var(--cquBlue);
  background-color: white;
  font-weight: bold;
}

button:disabled {
  color: var(--cquCharcoal);
  background-color: white;
  cursor: not-allowed;
}

/**
 * For ReactSelect diabled style in homepage.
 */
div.react-select--is-disabled {
  opacity: 0.5;
}
div.react-select {
  z-index: 3;
}

button + button {
  margin-left: 1rem;
}

nav button {
  margin-top: .5rem;
}

button.primaryButton {
  background-color: var(--cquBlue);
  color: #ffffff;
}

nav button.primaryButton {
  background-color: var(--cquBlue);
  color: white;
  font-weight: bold;
}

nav button.primaryButton:disabled {
  color: var(--cquLightCharcoal);
  background-color: rgba(0, 0, 0, 0.3);
  cursor: not-allowed;
}

button.secondaryButtonActive {
  background-color: var(--cquLightBlue);
  color: #ffffff;
}

button .buttonIcon {
  padding-right: 0.25rem;
}


button.dangerButton {
  background-color: var(--cquError);
  color: #ffffff;
}

div.column {
  float: left;
  padding-right: 1rem;
  text-align: left;
}

div.orderButton {
  display: inline-block;
  cursor: pointer;
  white-space: nowrap;
  word-break: break-all;
  border-radius: 1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: bold;
}

div.orderButton + div.orderButton {
  font-size: 90%;
  font-weight: normal;
}

div.closeButton {
  float: right;
  font-size: 1.5rem;
  color: var(--cquLightCharcoal);
  cursor: pointer;
}

.orderButton svg {
  padding: 0 0.4rem 0 0.2rem;
}
.orderButton a {
  padding: 0 0.4rem 0 0.2rem;
}
.orderButton span {
  padding: 0 0.4rem 0 0.2rem;
}
.orderButton div svg {
  padding: 0 0.4rem 0 0.4rem;
}
.orderButton div a {
  padding: 0 0.4rem 0 0.4rem;
}

tr.secondarySorting td {
  min-width: 6rem;
}

tr.primarySorting td div.orderButton.orderButtonPrimary {
  background-color: var(--cquLightBlue);
}
tr.secondarySorting td div.orderButton.orderButtonPrimary {
  background-color: var(--cquBlue);
}

div.orderButton.orderButtonPrimary svg {
  color: white;
}
div.orderButton.orderButtonPrimary a {
  color: white;
}

div.orderButton.orderButtonSecondary {
  background-color: #acb281;
}

div.orderButton.orderButtonSecondary svg {
  color: white;
}
div.orderButton.orderButtonSecondary a {
  color: white;
}

div.orderButton.orderButtonInactive {
  background-color: none;
}

div.orderButton.orderButtonInactive a {
  color: white;
}
div.orderButton.orderButtonInactive svg {
  color: var(--cquLightBlue);
}

.fa + .fa {
  margin-left: 0.2rem;
}

header > a {
  background-color: none;
}

th {
  padding: 0.5rem;
}

th.compactCell {
  padding: 0.5rem 0;
}

.highlight {
  background-color: #ffff00;
}

.unavailable {
  color: #aaa;
}

.rounded {
  border-radius: 1rem;
  padding: 0.3rem 0.8rem;
}

.icon-list {
  color: rgb(0, 48, 87);
  padding-right: 0.5rem;
}

.bg-cqu-green {
  background-color: var(--cquGreen);
}
.fg-cqu-green {
  color: var(--cquGreen);
}
.bg-cqu-blue {
  background-color: var(--cquBlue);
}
.fg-cqu-blue {
  color: var(--cquBlue);
}
.bg-cqu-charcoal {
  background-color: var(--cquCharcoal);
}
.fg-cqu-charcoal {
  color: var(--cquCharcoal);
}
.bg-cqu-50-blue {
  background-color: var(--cquLightBlue);
}
.fg-cqu-50-blue {
  color: var(--cquLightBlue);
}

header,
section > h4,
.primarySorting {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

section > h4 {
  color: white;
  background-color: var(--cquBlue);
  padding: 0.5rem 0.5rem 0.5rem 0.55rem;
  white-space: pre;
  margin: 0;
}

section > h4 > a {
  color: var(--cquGreen);
  background-color: inherit;
  padding: 0;
  margin: 0;
}

article.filterBar.historyPage > section > h4 {
  background-color: var(--cquCharcoal);
}


/**
.shade,
table tr {
  background: rgb(230,230,240);
  background: -moz-linear-gradient(top, rgba(230,230,240,1) 0%, rgba(255,255,255,1) 100%);
  background: -webkit-linear-gradient(top, rgba(230,230,240,1) 0%,rgba(255,255,255,1) 100%);
  background: linear-gradient(to bottom, rgba(230,230,240,1) 0%,rgba(255,255,255,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e6e6e6', endColorstr='#ffffff',GradientType=0 );
}
**/

input.findStudent {
  background: none;
  border: 0;
  padding: 0.1rem 0.3rem;
  border-bottom: #888 1px dotted;
  margin-left: 0.3rem;
  color: white;
}

input.findStudent:focus {
  background-color: white;
  color: var(--cquBlue);
}

table.oddEvenRows tbody tr:nth-child(odd) td {
  background-color: white;
}
table.oddEvenRows tbody tr:nth-child(even) td {
  background-color: #eee;
}
table.oddEvenRows tbody tr:nth-child(odd).predict-row-selected td {
  background-color: #eebcff;
}
table.oddEvenRows tbody tr:nth-child(even).predict-row-selected td {
  background-color: #feccff;
}

thead th,
.Ctable .row.head .col
{
  color: white;
  background-color: var(--cquBlue);
  padding: 0.5rem 0.2rem;
  font-size: 100%;
  overflow: hidden;
}

thead td a,
.Ctable .col a
{
  margin: 0;
  padding: 0;
}

thead td a + a,
.Ctable .col a + a
{
  margin-left: 3px;
}

.predictionTable th,
.predictionTable td,
.col {
  white-space: nowrap;
  word-break: break-all;
  overflow: hidden;
}

table.predictionTable {
  border-spacing: 0;
}

table.predictionTable tbody tr td {
  cursor: default;
}

table.predictionTable thead tr td {
  color: white;
  background-color: var(--cquLightBlue);
  white-space: normal;
  word-break: normal;
  text-align: center;
  max-height: 1.5rem;
  padding: .3rem;
  line-height: 1.6rem;
  cursor: default;
  /* font-size: 2rem; */
  overflow: hidden;

  /* Sub-pixel values result in anti-aliased text for sticky-positioned elements. */
  position: sticky;
  z-index: 10;
  top: 0;
}

table.predictionTable thead tr.primarySorting td {
  color: white;
  white-space: normal;
  word-break: normal;
  text-align: center;
  max-height: 1.5rem;
  padding: .3rem;
  line-height: 1.6rem;
  overflow: hidden;
  background-color: var(--cquBlue);
  border-left: 1px dotted var(--cquLightBlue);
}

table.predictionTable tbody tr td.columnSelection {
  color: var(--cquLightCharcoal);
}

table.predictionTable thead tr.primarySorting.historyPage td {
  background-color: var(--cquCharcoal);
}

div#JSON {
  background-color: #e6e6e6;
}

div.side-scrolling {
  overflow-x: scroll;
}

div.verticalScroll {
  /** height: 100%; **/
  overflow-x: hidden;
  overflow-y: auto;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-large {
  font-weight: bold;
  font-size: 16pt;
}

.no-wrap {
  white-space: nowrap;
}

.horizontal-padding {
  margin: 0 0.5rem;
}

.vertical-padding {
  margin: 0.4rem 0;
}

.legend-padding {
  margin: 0.4rem 0.4rem 0.4rem 0;
}

.padding {
  margin: 1rem;
}

.pad {
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.vertical-space-above {
  margin-top: 1rem;
}

.vertical-space {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.textInsert {
  cursor: pointer;
}

.textInsert svg {
  color: var(--cquMidBlue);
}

label {
  user-select: none;
  cursor: pointer;
}

small {
  color: #a0a0a0;
}

.avatar-circle {
  width: 6rem;
  height: 6rem;
  background-color: #143258;
  text-align: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
}

.avatar-initials {
  position: relative;
  top: 1.4rem; /* 25% of parent */
  font-size: 3rem; /* 50% of parent */
  line-height: 3rem; /* 50% of parent */
  color: #fff;
  font-weight: bold;
}

.fg-low,
a.fg-low,
td.fg-low {
  color: #eb1e23;
}
.fg-medium,
a.fg-medium,
td.fg-medium {
  color: #ffb100;
}
.fg-high,
a.fg-high,
td.fg-high {
  color: #c2d601;
}

.fg-null {
  color: var(--cquLightCharcoal);
}

td.bg-low,
div.bg-low {
  text-align: right;
  border-right: 1rem solid #eb1e23;
}
td.bg-medium,
div.bg-medium {
  text-align: right;
  border-right: 1rem solid #ffb100;
}
td.bg-high,
div.bg-high {
  text-align: right;
  border-right: 1rem solid #c2d601;
}

input.config {
  font-size: 105%;
  margin: none;
  padding: 2px;
  border: none;
  border-bottom: 1px dotted gray;
}

input.config-percentage {
  width: 3rem;
  text-align: right;
}

input.config-text {
  width: 16rem;
  margin-right: 1rem;
}

.chartShadow {
  background-image: -moz-radial-gradient(ellipse closest-corner at 50% 92%, rgba(100, 100, 100, 0.7), rgba(100, 100, 100, 0.0) 48%);
  background-image: -webkit-radial-gradient(ellipse closest-corner at 50% 92%, rgba(100, 100, 100, 0.7), rgba(100, 100, 100, 0.0) 48%);
  background-image: -o-radial-gradient(ellipse closest-corner at 50% 92%, rgba(100, 100, 100, 0.7), rgba(100, 100, 100, 0.0) 48%);
  background-image: -ms-radial-gradient(ellipse closest-corner at 50% 92%, rgba(100, 100, 100, 0.7), rgba(100, 100, 100, 0.0) 48%);
  background-image: radial-gradient(ellipse closest-corner at 50% 92%, rgba(100, 100, 100, 0.7), rgba(100, 100, 100, 0.0) 48%);
}

.barShadow {
  padding-top: 20px;
  background-image: -moz-radial-gradient(ellipse closest-corner at 55% 84%, rgba(100, 100, 100, 0.3), rgba(100, 100, 100, 0.0) 48%);
  background-image: -webkit-radial-gradient(ellipse closest-corner at 55% 84%, rgba(100, 100, 100, 0.3), rgba(100, 100, 100, 0.0) 48%);
  background-image: -o-radial-gradient(ellipse closest-corner at 55% 84%, rgba(100, 100, 100, 0.3), rgba(100, 100, 100, 0.0) 48%);
  background-image: -ms-radial-gradient(ellipse closest-corner at 55% 84%, rgba(100, 100, 100, 0.3), rgba(100, 100, 100, 0.0) 48%);
  background-image: radial-gradient(ellipse closest-corner at 55% 84%, rgba(100, 100, 100, 0.3), rgba(100, 100, 100, 0.0) 48%);
}

.querySummary {
  float: right;
  color: var(--cquLightCharcoal);
  font-style: italic;
  font-family: serif;
  padding-left: 0.5rem;
}

div#Preferences {
  text-align: right;
  padding-right: 1rem;
}

/*
div#feedbackForm aside {
  top: 10vh;
  left: 10vw;
  width: auto;
  height: auto;
  max-width: 80vw;
}
*/

div#feedbackForm textarea {
  width: 59vw;
  height: 15vh;
  padding: 0.5rem;
  background-color: #fafad2;
  line-height: 1.3rem;
}

aside {
  text-align: left;
  position: fixed;
  overflow: auto;
  z-index: 1111;
  background-color: white;
  padding: 1rem;
  border: 1px solid var(--cquCharcoal);
  -webkit-box-shadow: 3px 3px 12px 0px rgba(0,0,0,0.5);
  -moz-box-shadow: 3px 3px 12px 0px rgba(0,0,0,0.5);
  box-shadow: 3px 3px 12px 0px rgba(0,0,0,0.5);
}

aside.topLeft {
  top: 1rem;
  right: 1rem;
  width: 26rem;
}

aside.middleCenter {
  top: 20vh;
  left: 20vw;
  height: 60vh;
  width: 60vw;
}

div.featuredFraction {
  margin: 0 auto;
  text-align: center;
  font-size: 200%;
  width: 6rem;
  max-width: 6rem;
  padding-top: 2rem;
}

div.featuredFractionDenominator {
  border-top: 2px solid black;
}


header div.toolbar {
  color: white;
  padding-top: 0.9rem;
}

header div.toolbar * {
  padding-left: .5rem;
  padding-right: .5rem;
}

.featureRow {
  margin: .2rem;
  text-align: center;
}

.leftAlign {
  text-align: left;
}

.rightAlign {
  text-align: right;
}

.predictionRow1 a,
.featureRow a {
  color: var(--cquBlueLink);
  text-decoration: none;
  draggable: none;
}

.predictionRow1 a:hover,
.featureRow a:hover {
  text-decoration: underline;
  color: var(--cquBlueLink);
}

/**
.requiredRow {
  background-color: rgba(193,214,39, 0.4);
  border-radius: 1rem;
  border: dotted 2px rgba(193,214,39, 1);
}
**/

.flexRow {
  display: flex;
  flex-direction: row;
}

.leftHalf {
  float: left;
  width: 50%;
}

.leftHalfPadded {
  padding: 1rem;
  width: calc(50% - 2rem);
}

.leftThird {
  float: left;
  width: 33%;
}

.rightThird {
  float: right;
  width: 33%;
}

.leftThirdPadded {
  padding: 1rem;
  width: calc(33% - 2rem);
}

.leftQuarter {
  float: left;
  width: 25%;
}

.wrapText {
  white-space: normal;
}

.hardWrap {
  overflow-wrap: break-word;
  white-space: pre-wrap;
}

.noWrap {
  white-space: pre;
}

.spaceChildren * {
  margin-right: 1rem;
}

.spaceChildren *:last-child {
  margin-right: 0;

}

.predictionRow1 {
  text-align: center;
  font-size: 100%;
}

.predictionRow2 {
  text-align: center;
  padding-top: 0.4rem;
  font-size: 85%;
  color: #a6a6a6;
}

.predictionRow2 a {
  color: #a6a6a6;
  border-bottom: 1px solid #e0e0e0;
}

td {
  padding: 0.4rem;
}

td.columnCentered {
  text-align: center;
}

.largeScreenOnly {
  display: none;
}

th.smallScreenColumnHide,
td.smallScreenColumnHide {
  display: table-cell;
}

th.wideScreenColumnOnly,
td.wideScreenColumnOnly {
  display: none;
}

.dotCounter {
  color: red;
  font-size: .2rem;
  position: absolute;
  top: 2px;
  right: 2px;
  z-index: 10;
}

table.oddEvenRows tbody td.interactionsThisWeek {
  cursor: pointer;
  /* --cquLightBlue: rgb(128, 150, 166); */
  background: -moz-linear-gradient(45deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 85%, rgba(214,214,214,1) 86%, rgba(128, 150, 166, 1) 86%, rgba(128, 150, 166, 1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(45deg, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 85%,rgba(214,214,214,1) 86%, rgba(128, 150, 166, 1) 86%, rgba(128, 150, 166, 1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(45deg, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 85%,rgba(214,214,214,1) 86%, rgba(128, 150, 166, 1) 86%, rgba(128, 150, 166, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ff0004',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

table.oddEvenRows tbody td.interactionsThisWeek:hover {
  background: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 85%, rgba(214,214,214,1) 86%, rgba(0, 48, 87, 1) 86%, rgba(0, 48, 87, 1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0) 0%,rgba(255, 255, 255, 0) 85%,rgba(214,214,214,1) 86%, rgba(0, 48, 87, 1) 86%, rgba(0, 48, 87, 1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(45deg, rgba(255, 255, 255, 0) 0%,rgba(255, 255, 255, 0) 85%,rgba(214,214,214,1) 86%, rgba(0, 48, 87, 1) 86%, rgba(0, 48, 87, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ff0004',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

@media only screen and (min-width: 140rem) {
  td {
    padding: 0.2rem;
  }
}


/* OLD REACT AUTOCOMPLETE */

.numberRange {
  width: calc(100% - 1rem);
  padding: 0.5rem;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 1rem;
  border: 0px;
  border-radius: 3px;
}

.autocomplete {
  width: 100%;
  height: 30px;
  padding: 1rem;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 1.5rem;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.autocomplete {
  width: 100%;
  height: 30px;
  padding: 1rem;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 1.5rem;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.autocompleteHalfLeft {
  width: calc(48% - 2rem);
  height: 30px;
  padding: 1rem;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 1.5rem;
  border: 1px solid #aaa;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.autocompleteHalfRight {
  width: calc(48% - 2rem);
  height: 30px;
  padding: 1rem;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 1.5rem;
  border: 1px solid #aaa;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.autocomplete-suggestion {
  padding: 4px 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 1rem;
}

.autocomplete-error {
  padding: 4px 20px;
  color: white;
}

@media only screen and (max-width: 115rem) {

}

@media only screen and (min-width: 180rem) {

}

.requiredAsterisk {
  color: #ad0000;
}

/* Generic HTML popover, to left of item */
td.popoverContainer {
  overflow: visible;
}

.popoverChild {
  display: none;
}

.popoverParent {
   position: relative;
}

.popoverParent:hover .popoverChild {
  position: absolute;
  top: 2rem;
  right: 4rem;
  overflow: hidden;
  font-size: small;
  width: 12rem;
  text-align: left;
  background-color: white;
  box-shadow: 3px 3px 12px 0px rgba(0,0,0,0.5);
  display: block;
  z-index: 10;
  border: 1px solid var(--cquLightCharcoal);
  padding: 1rem;
}

.popoverHeading {
  margin-top: .25rem;
  font-weight: bold;
  color: var(--cquBlue);
}

.popoverBody {
  color: var(--cquCharcoal);
  margin-bottom: .25rem;
}

.popoverEndNote {
  margin-top: .25rem;
  font-style: italic;
  color: var(--cquCharcoal);
}

/* h2 */
.popverTitle {
  color: white;
  background-color: var(--cquBlue);
  padding: 1rem;
  margin-top: 0;
}

.popverTitleRightButton {
  font-size: 0.85rem;
  color: var(--cquBlue);
  background-color: white;
  float: right;
  cursor: pointer;
  padding: 0.4rem 0.8rem;
  margin-left: 0.8rem;
}

/* Interactions popover */

.interactionsList, .feedbackList {
  margin-left: 2rem;
  cursor: default;    
}

.interactionsListItem, .feedbackListItem {
  clear: both;
  width: 95%;
  padding-top: 1rem;
  border-top: 1px solid var(--cquLightCharcoal);
  margin-top: 1rem;
}

.interactionsListItemIcon, .feedbackListItemIcon {
  float: left;
  width: calc(10% - 2rem);
  color: var(--cquLightBlue);
  font-size: 24pt;
  padding: 0.9rem;
}

.interactionsListItemInfo, .feedbackListItemInfo {
  float: left;
  width: calc(20% - 2rem);
}

.interactionsListItemContent, .feedbackListItemContent {
  float: left;
  width: calc(70% - 2rem);
  padding-bottom: 1rem;
}

.interactionsListItemContent p, feedbackListItemContent p {
  white-space: pre-wrap;
}

.interactionIcon {
  color: var(--cquLightBlue);
}

.feedbackIcon {
  color: var(--cquLightBlue);
  margin-left: 40%;
}

.interactionIcon.popoverParent , .feedbackIcon.popoverParent{
  cursor: pointer;
}

.interactionIcon:hover, .feedbackIcon:hover {
  color: var(--cquBlue);
}

/* Unit Dashboard */
.interactionTotalBox {
  float: left;
  width: calc(50% - 2rem);
  padding: 1.5rem 0.25rem 0rem 1.75rem;
}

.interactionTotalBox:nth-child(even) {
  padding: 1.5rem 1.75rem 0rem 0.25rem;
}

.interactionTotalBox .number {
  color: var(--cquCharcoal);
  font-size: 3rem;
}


/* Interactions form */

#interactionForm table {
  width: 90%;
}

#interactionForm table tbody tr td:first-child {
  width: 11rem;
}

#interactionForm input[type=text] {
  width: 100%;
  margin: 0;
  padding: 0.5rem;
  border: 2px solid #eee;
  border-radius: 10px;
  box-shadow: none;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  visibility: inherit !important;
  font-size: 1.1rem;
}

#interactionForm textarea {
  width: 100%;
  height: 30vh;
  padding: 0.5rem;
  line-height: 1.3rem;
  border: 2px solid #eee;
  border-radius: 10px;
  box-shadow: none;
  box-sizing: border-box;
  visibility: inherit !important;
  font-size: 1.1rem;
  font-family: inherit;
}

#interactionForm input.error,
#interactionForm textarea.error,
form.siteConfigForm input.error {
  background-color: #fadada;
}

#feedbackForm .errorMessage,
#interactionForm .errorMessage,
form.siteConfigForm .errorMessage {
  color: #ad0000;
  clear: both;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}

#interactionForm td {
  vertical-align: top;
}

/*
 * Number bar for graph totals
 */
table.numberBar {
  width: 100%;
}

table.numberBar tbody tr,
table.numberBar tbody tr td {
  background: none;
  text-align: left;
}

table.numberBar tbody tr td.numberBarNumber {
  width: 42%;
  font-size: 2.5rem;
  text-align: right;
  font-family: 'Abril Fatface', serif;
  font-weight: bold;
  color: var(--cquBlue);
  padding: 0;
}

/* generic form elements to match react-autocomplete */

#genericForm input[type=text] {
  width: 100%;
  background-color: #fafad2;
  margin: 0;
  padding: 0.5rem;
  border: 1px solid var(--cquLightCharcoal);
  box-sizing: border-box;
}

#genericForm textarea {
  width: calc(100% - 1rem);
  height: 30vh;
  padding: 0.5rem;
  background-color: #fafad2;
  line-height: 1.3rem;
}

/* Icon box in filterBar */

.iconBox {
  width: 7rem;
  background-color: #eee;
}

.tableCenter {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.mouseOverToggle .mouseOverInactive {
  display: block;
}

.mouseOverToggle:hover .mouseOverInactive {
  display: none;
}

.mouseOverToggle .mouseOverActive {
  display: none;
}

.mouseOverToggle:hover .mouseOverActive {
  display: block;
}

.plotTooltip {
  background-color: var(--cquBlue);
  padding: 1rem;
  opacity: 0.8;
  color: white;
}
.plotTooltip h3, .plotTooltip p {
  margin: 0;
  padding: 0;
}

.gradesHeader {
  padding: 0.5rem;
  background-color: var(--cquGreen);
  font-weight: bold;
}

div.slider .value {
  text-align: center;
}

article.sidebar section {
  margin: 0;
  background: #fff;
  box-shadow: 5px 5px 10px #ddd;
}
article.sidebar section.filterBox {
  padding: 1rem;
  width: 300px;
  overflow-y: auto;
}

.filterBoxTooltip {
}

article.sidebar section.filterBox .header {
  text-align: right;
  min-height: 2.0rem;
}

article.sidebar section.filterBox fieldset {
  border: 0;
  margin-bottom: 0.5rem;
  min-width: 0px; /* prevent select from growing outside bounds of container */
}

article.sidebar section.filterBox fieldset legend {
  font-weight: bold;
}

article.sidebar section.filterBox .filterButtons > * {
  margin-right: 0.5rem;
}

article.sidebar section.infoBox {
  width: 300px;
  padding: 1rem;
  overflow-y: auto;
}

article.sidebar section.infoBox .header {
  font-weight: bold;
  border-bottom-color: var(--cquGreen);
  border-bottom-width: 2px;
  border-bottom-style: solid;
  margin-top: 3rem;
  margin-bottom: 0.5rem;
}

article.sidebar section.infoBox .header:first-child {
  margin-top: 1rem;
}

article.sidebar section.infoBox .content > * {
  margin-bottom: 0.2rem;
}

/* Email Preview in Interactions */

div.emailPreviewList {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  background-color: var(--cquLightCharcoal);
  height: 27rem;
  overflow-y: auto;
}

div.emailRecipientList {
  width: 100%;
  background-color: var(--cquLightCharcoal);
  height: 27rem;
  overflow-y: auto;
}

div.emailPreview {
  width: 650px;
  margin: 1rem;
  padding: 1rem;
  background-color: white;
  box-shadow: 3px 3px 12px 0px rgba(0,0,0,0.5);
}

div.emailPreviewSubject {
  padding: 0.5rem;
  color: white;
  background-color: var(--cquBlue);
  font-weight: bold;
}

div.emailPreviewBodyText {
  padding: 1rem 0;
}

/* Generic elements; position last to prevent overrides */

.clickable {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.noPadding {
  padding: 0;
}

.badge {
  position: relative;
  right: 0.25rem;
  top: -0.3rem;
  font-size: .6rem;
  padding: 0.2rem;
  border-radius: 50%;
  line-height: .75em;
  color: white;
  background: var(--cquBlue);
  font-weight: bold;
  float: right;
}

.definitionIcon {
  color: var(--cquLightBlue);
  font-size: 0.8rem;
  cursor: pointer;
  margin-bottom: 0.3rem;
}

.interactionTable .definitionIcon {
  color: white;
}

/**
 * ---------------------------------------------------------
 * Student Details
 * ---------------------------------------------------------
 */

div.studentDetailsColumns {
  display: flex;
  flex-flow: row wrap;
  align-content: stretch;
}

div.numberBox {
  color: var(--cquCharcoal);
  text-align: center;
  background-color: white;
  box-shadow: 5px 5px 10px #ddd;
}

div.numberBox div:first-child {
  color: var(--cquBlue);
  font-weight: bold;
  font-size: 2.0rem;
  line-height: 2.7rem;
}

div.shadowBox {
  padding: 1rem;
  margin: 1rem;
  background-color: white;
  box-shadow: 5px 5px 10px #ddd;
}

/**
 * ---------------------------------------------------------
 * Unit Dashboard
 * ---------------------------------------------------------
 */


@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  /* MSIE11 hacks */
  main {
    display: block;
  }

  .UnitDashboard {
    display: -ms-grid;
    -ms-grid-columns: auto 1fr;
  }

  .UnitDashboard .sidebar {
    -ms-grid-column: 1;
    display: -ms-grid;
    -ms-grid-columns: auto 1fr;
  }

  .UnitDashboard .sidebar .infoBox {
    -ms-grid-column: 1;
  }

  .UnitDashboard .sidebar .Buttons {
    -ms-grid-column: 2;
  }

  .UnitDashboard .UnitWidgets {
    -ms-grid-column: 2;
  }
}


/**
 * ---------------------------------------------------------
 * Announcements page
 * ---------------------------------------------------------
 */
.AnnouncementsPage {
  display: grid;
  grid-template-columns: minmax(240px, 360px) minmax(360px, 1100px);
  gap: 1rem;
  overflow-y: unset;
  flex-grow: 1;

  position: relative;
  padding: 1.2rem;
  margin-inline: auto;
}

.AnnouncementsPage section {
  margin: 0;
}

.AnnouncementsPage > section,
.AnnouncementsPage > .AnnouncementViewPanel {
  display: flex;
  flex-direction: column;

  gap: 1rem;
}

.AnnouncementsPage > .AnnouncementViewPanel > section {
  flex-grow: 1;
}

.AnnouncementsPage .AnnouncementListSearchContainer {
  display: flex;

  padding-inline: 1rem;
}

.AnnouncementsPage .AnnouncementListContainerHeightLimiter,
.AnnouncementsPage .AnnouncementView .AnnouncementViewBodyContainer {
  flex: 1;
  position: relative;
}

.AnnouncementsPage .AnnouncementListContainer {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  position: absolute;

  gap: .4rem;
  max-height: 100%;
  width: 100%;
  overflow-y: scroll;
}

.AnnouncementsPage .AnnouncementListItemContainer {
  padding-inline: 1rem;
  padding-block: .6rem;
}

.AnnouncementsPage .AnnouncementListItemContainer.active {
  background-color: #feccff;
}

.AnnouncementsPage .AnnouncementListItemContainer:hover:not(.active) {
  background-color: var(--cquLightCharcoal);
  cursor: pointer;
}

.AnnouncementsPage .AnnouncementListItemSummary {
  overflow: hidden;
  display: -webkit-box;
   -webkit-line-clamp: 3;
           line-clamp: 3;
   -webkit-box-orient: vertical;
}

.AnnouncementListItemDetails {
  display: flex;
  justify-content: space-between;

  color: var(--cquCharcoal);
  font-size: 0.9rem;
}

.AnnouncementsPage .AnnouncementListSearchInput {
  width: 100%;
}

.AnnouncementView {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  padding: 1rem;
  gap: 1rem;
}

.AnnouncementView > h1 {
  margin: 0;
}

.AnnouncementsPage .AnnouncementView .AnnouncementViewBody {
  position: absolute;

  max-height: 100%;
  overflow-y: scroll;
}

.AnnouncementsPage .AnnouncementView .AnnouncementViewBody a {
  font-weight: bold;
  text-decoration: underline;
}

.AnnouncementViewPanel .ButtonListContainer {
  display: flex;
  justify-content: space-between;

  gap: 1rem;
}

.AnnouncementModalFieldset {
  display: flex;
  flex-direction: column;

  gap: 0.5rem;
  padding: 1rem;
  border: 2px solid #eee;
  border-radius: 10px;
}

/* Notifications popup */
#Notifications {
  top: 1rem;
  max-height: calc(100vh - 2rem);
  overflow-y: scroll;
}

#Notifications,
.notifications-header h3,
#Templates h3 {
  padding: unset;
  margin: unset;
}

.notifications-header div.closeButton {
  float:unset;
}

.notifications-header,
.notification-info {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.notifications-header,
.notification {
  padding: 1rem;
}

.notifications-header h3,
.notification-info-what {
  flex: 1;
}

.notification:hover {
  background-color: var(--cquLightCharcoal);
  cursor: pointer;
}

.notification:not(.read) {
  font-weight: bold;
}

.notification-markall {
  font-size: 0.75em;
  text-decoration: underline;
}

.notification-info {
  font-size: 0.75em;
  padding-bottom: 0.25rem;
}

.notification-body,
.notification-info {
  color: black;
}

#buttonToShowNotifications {
  position: relative;
}
#buttonToShowNotifications[data-unread]:not([data-unread='0'])::before {
  content: '';
  position: absolute;
  width: 12px;
  height: 12px;
  right: 12px;
  top: -6px;

  background-color: var(--cquError);
  color: white;
  border-radius: 100%;
}

/**
 * ---------------------------------------------------------
 * Definitions page
 * ---------------------------------------------------------
 */
.DefinitionsPage {
  display: grid;
  grid-template-columns: minmax(240px, 360px) minmax(360px, 1100px);
  gap: 1rem;
  overflow-y: unset;
  flex-grow: 1;

  position: relative;
  padding: 1.2rem;
  margin-inline: auto;
}

.DefinitionsPage section {
  margin: 0;
}

.DefinitionsPage > section,
.DefinitionsPage > .DefinitionViewPanel {
  display: flex;
  flex-direction: column;

  gap: 1rem;
}

.DefinitionsPage > .DefinitionViewPanel > section {
  flex-grow: 1;
}

.DefinitionsPage .DefinitionListSearchContainer {
  display: flex;

  padding-inline: 1rem;
}

.DefinitionsPage .DefinitionListContainerHeightLimiter,
.DefinitionsPage .DefinitionView .DefinitionViewBodyContainer {
  flex: 1;
  position: relative;
}

.DefinitionsPage .DefinitionListContainer {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  position: absolute;

  gap: .4rem;
  max-height: 100%;
  width: 100%;
  overflow-y: scroll;
}

.DefinitionsPage .DefinitionListItemContainer {
  padding-inline: 1rem;
  padding-block: .6rem;
}

.DefinitionsPage .DefinitionListItemContainer.active {
  background-color: #feccff;
}

.DefinitionsPage .DefinitionListItemContainer:hover:not(.active) {
  background-color: var(--cquLightCharcoal);
  cursor: pointer;
}

.DefinitionsPage .DefinitionListItemSummary {
  overflow: hidden;
  display: -webkit-box;
   -webkit-line-clamp: 3;
           line-clamp: 3;
   -webkit-box-orient: vertical;
}

.DefinitionListItemDetails {
  display: flex;
  justify-content: space-between;

  color: var(--cquCharcoal);
  font-size: 0.9rem;
}

.DefinitionsPage .DefinitionListSearchInput {
  width: 100%;
}

.DefinitionView {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  padding: 1rem;
  gap: 1rem;
}

.DefinitionView > h1 {
  margin: 0;
}

.DefinitionsPage .DefinitionView .DefinitionViewBody {
  position: absolute;

  max-height: 100%;
  overflow-y: scroll;
}

.DefinitionViewPanel .ButtonListContainer {
  display: flex;
  justify-content: space-between;

  gap: 1rem;
}

.DefinitionModalFieldset {
  display: flex;
  flex-direction: column;

  gap: 0.5rem;
  padding: 1rem;
  border: 2px solid #eee;
  border-radius: 10px;
}

/**
 * ---------------------------------------------------------
 * Templates model over tiny editor
 * ---------------------------------------------------------
 */
#Templates {
  display: flex;
  flex-direction: column;

  gap: 0.75rem;
  height: 50vh;
  width: 80vw;
  top: 25vh;
  left: 10vw;
}

#Templates .templates-header,
#Templates .templates-footer {
  flex-shrink: 0; /* Prevent them from shrinking */
}

#Templates .templates-main {
  flex-grow: 1;
}

#Templates .flex-container {
  display: flex;
  gap: 0.75rem;
}

#Templates .flex-container.column {
  flex-direction: column;
  padding: unset;
}

#Templates .flex-container.align-items-center {
  align-items: center;
}

#Templates .flex-container.justify-content-center {
  justify-content: center;
}

#Templates .grid-container {
  display: grid;
  grid-template-columns: 300px auto;

  gap: 0.75rem;
}

#Templates .grid-container > .justify-self-end {
  justify-self: end;
}

#Templates .closeButton {
  float: right;
}

#Templates .flex-container > .grow,
#Templates > .grow {
  flex-grow: 1;
}

#Templates .bordered {
  border: 1px solid var(--cquCharcoal);
}

#Templates .stretch-self {
  width: 100%;
  box-sizing: border-box;
}

#Templates .templates-search {
  width: 100%;
  box-sizing: border-box;
  flex-shrink: 0; /* Prevent them from shrinking */
}

#Templates .height-limiter {
  position: relative;
}

#Templates .height-limiter > * {
  position: absolute;
  max-height: 100%;
}

#Templates .template-list {
  gap: .5rem;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 0.5rem;
}

#Templates .template-list-item {
  align-items: center;
  gap: 0.75rem;
  padding: 0.25rem 0.4rem;
  cursor: pointer;
}

#Templates .template-list-item:hover {
  background-color: var(--cquLightCharcoal);
}

#Templates .template-list-item.template.active {
  background-color: #feccff;
}

#Templates .template-list-item.category {
  align-items: flex-start;
}

#Templates .template-list-item.category svg {
  margin-top: 3px;
}

/**
 * ---------------------------------------------------------
 * Templates page
 * ---------------------------------------------------------
 */
 .TemplatesPage {
  display: grid;
  grid-template-columns: minmax(240px, 360px) minmax(360px, 1100px);
  gap: 1rem;
  overflow-y: unset;
  flex-grow: 1;

  position: relative;
  padding: 1.2rem;
  margin-inline: auto;
}

.TemplatesPage section {
  margin: 0;
}

.TemplatesPage > section,
.TemplatesPage > .TemplateViewPanel {
  display: flex;
  flex-direction: column;

  gap: 1rem;
}

.TemplatesPage > .TemplateViewPanel > section {
  flex-grow: 1;
}

.TemplatesPage .TemplateListSearchContainer {
  display: flex;

  padding-inline: 1rem;
}

.TemplatesPage .TemplateListContainerHeightLimiter,
.TemplatesPage .TemplateView .TemplateViewBodyContainer {
  flex: 1;
  position: relative;
}

.TemplatesPage .TemplateListContainer {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  position: absolute;

  gap: .4rem;
  max-height: 100%;
  width: 100%;
  overflow-y: scroll;
}

.TemplatesPage .TemplateListItemContainer.category {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  position: relative;
  gap: .4rem;
}

.TemplatesPage .TemplateListItemContainer.category .category-edit-button-container {
  display: none;
  position: absolute;
  right: 1rem;
}

.TemplatesPage .TemplateListItemContainer.category:hover .category-edit-button-container {
  display: block;
}

.TemplatesPage .TemplateListItemContainer.category .category-edit-button-container button {
  font-size: .7em;
  padding: .4rem .8rem;
  margin-left: 0.8rem;
}



.TemplatesPage .TemplateListItemContainer {
  padding-inline: 1rem;
  padding-block: .6rem;
}

.TemplatesPage .TemplateListItemContainer.active {
  background-color: #feccff;
}

.TemplatesPage .TemplateListItemContainer:hover:not(.active) {
  background-color: var(--cquLightCharcoal);
  cursor: pointer;
}

.TemplatesPage .TemplateListItemSummary {
  overflow: hidden;
  display: -webkit-box;
   -webkit-line-clamp: 3;
           line-clamp: 3;
   -webkit-box-orient: vertical;
}

.TemplateListItemDetails {
  display: flex;
  justify-content: space-between;

  color: var(--cquCharcoal);
  font-size: 0.9rem;
}

.TemplatesPage .TemplateListSearchInput {
  width: 100%;
}

.TemplateView {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  padding: 1rem;
  gap: 1rem;
}

.TemplateView > h1 {
  margin: 0;
}

.TemplatesPage .TemplateView .TemplateViewBody {
  position: absolute;

  max-height: 100%;
  overflow-y: scroll;
}

.TemplateViewPanel .ButtonListContainer {
  display: flex;
  justify-content: space-between;

  gap: 1rem;
}

.TemplateModalFieldset {
  display: flex;
  flex-direction: column;

  gap: 0.5rem;
  padding: 1rem;
  border: 2px solid #eee;
  border-radius: 10px;
}